import { Preset } from '../types/preset';

const allPresets: Preset[] = [
    {
        title: 'Facebook feed image',
        identifier: 'facebook_feed_image',
        searchIndex: 'meta, facebook, feed, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'Use the image format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 600,
            minHeight: 600,
            maxSizeKb: 1024,
            maxAspectRatio: '191:100',
            minAspectRatio: '4:5',
            aspectRatioPercentageTolerance: 3
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            maxAspectRatio: '191:100',
            minAspectRatio: '1:1'
        }
    },
    {
        title: 'Facebook stories image',
        identifier: 'facebook_stories_image',
        searchIndex: 'meta, facebook, marketplace, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Facebook Stories to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            maxSizeKb: 30000,
            minWidth: 500,
            aspectRatioPercentageTolerance: 1,
            minAspectRatio: '9:16',
            maxAspectRatio: '1.91:1'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            aspectRatio: '9:16'
        }
    },
    {
        title: 'Facebook marketplace image',
        identifier: 'facebook_marketplace_image',
        searchIndex: 'meta, facebook, story, stories, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Facebook Instant Articles to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            maxSizeKb: 30000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            aspectRatio: '1:1'
        }
    },
    {
        title: 'Facebook reels image',
        identifier: 'facebook_reels_image',
        searchIndex: 'meta, facebook, story, stories, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in the Ads on Facebook Reels placement to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minHeight: 600,
            minWidth: 600,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 3
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            aspectRatio: '1:1'
        }
    },
    {
        title: 'Facebook in-stream video(image)',
        identifier: 'facebook_instream_video_image',
        searchIndex: 'meta, facebook, in-stream, stream, video, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minHeight: 600,
            minWidth: 600,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 3,
            minAspectRatio: '4:5',
            maxAspectRatio: '1.91:1'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            maxAspectRatio: '191:100',
            minAspectRatio: '1:1'
        }
    },
    {
        title: 'Facebook feeds image',
        identifier: 'facebook_video_feeds_image',
        searchIndex: 'meta, facebook, feeds, feed, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'Use the image format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minHeight: 600,
            minWidth: 600,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 3,
            minAspectRatio: '4:5',
            maxAspectRatio: '1.91:1'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            maxAspectRatio: '191:100',
            minAspectRatio: '1:1'
        }
    },
    {
        title: 'Facebook search results image',
        identifier: 'facebook_search_results_image',
        searchIndex: 'meta, facebook, search, results, result, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Facebook Search Results to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minHeight: 600,
            minWidth: 600,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 3,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            maxAspectRatio: '191:100',
            minAspectRatio: '1:1'
        }
    },
    {
        title: 'Facebook business explore image',
        identifier: 'facebook_business_explore_image',
        searchIndex: 'meta, facebook, business, explore, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Facebook Business Explore to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minHeight: 600,
            minWidth: 600,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 3,
            minAspectRatio: '4:5',
            maxAspectRatio: '1.91:1'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            maxAspectRatio: '191:100',
            minAspectRatio: '1:1'
        }
    },
    {
        title: 'Instagram profile feed image',
        identifier: 'instagram_profile_feed_image',
        searchIndex: 'meta, instagram, profile, feed, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Instagram profile feed to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minHeight: 400,
            minWidth: 500,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 1,
            minAspectRatio: '4:5',
            maxAspectRatio: '1.91:1'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            aspectRatio: '1:1'
        }
    },
    {
        title: 'Instagram feed image',
        identifier: 'instagram_feed_image',
        searchIndex: 'meta, instagram, feed, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Instagram Feed to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 500,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 1,
            minAspectRatio: '4:5',
            maxAspectRatio: '1.91:1'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            aspectRatio: '1:1'
        }
    },
    {
        title: 'Instagram story image',
        identifier: 'instagram_story_image',
        searchIndex: 'meta, instagram, feed, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Instagram Stories to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 500,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 1
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            maxAspectRatio: '16:9',
            minAspectRatio: '4:5',
            aspectRatio: '9:16'
        }
    },
    {
        title: 'Instagram explore image',
        identifier: 'instagram_explore_image',
        searchIndex: 'meta, instagram, explore, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Instagram Explore to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality. Your ads in Instagram Explore will appear in the same format as your ads in Instagram Feed.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 500,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 1
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            maxAspectRatio: '16:9',
            minAspectRatio: '9:16'
        }
    },
    {
        title: 'Instagram explore home image',
        identifier: 'instagram_explore_home_image',
        searchIndex: 'meta, instagram, explore, home, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the single image format in Instagram Explore home to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 500,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 1,
            minAspectRatio: '4:5',
            maxAspectRatio: '1.91:100'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            aspectRatio: '1:1'
        }
    },
    {
        title: 'Audience Network Native, Banner and Interstitial image',
        identifier: 'meta_audience_network_native_banner_and_interstitial',
        searchIndex: 'meta, network, native, banner, interstitial',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Audience Network to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 398,
            minHeight: 208,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 1
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9'
        }
    },
    {
        title: 'Audience Network Rewarded image',
        identifier: 'meta_audience_network_rewarded_video',
        searchIndex: 'meta, audience, network, rewarded, image',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'Use the image format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 600,
            minHeight: 600,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 3
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            minAspectRatio: '1:1',
            maxAspectRatio: '1.91:1'
        }
    },
    {
        title: 'Messenger stories image',
        identifier: 'meta_messanger_stories',
        searchIndex: 'meta, facebook, messenger, stories',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in Messenger Stories to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 500,
            maxSizeKb: 30000,
            aspectRatioPercentageTolerance: 1,
            minAspectRatio: '9:16',
            maxAspectRatio: '1.91:1'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            aspectRatio: '9:16'
        }
    },
    {
        title: 'Meta right column image',
        identifier: 'meta_right_column',
        searchIndex: 'meta, right, image, column',
        channel: 'social',
        platform: 'meta',
        assetType: 'image',
        fileExtension: ['jpg', 'png'],
        description:
            'You can use the image format in the Facebook Right Column to show off your product, service or brand. Choose an interesting image of a product to encourage people to learn more about it, or use an eye-catching photo to showcase your brand personality.',
        restrictions: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            maxSizeKb: 30000,
            minAspectRatio: '1:1',
            maxAspectRatio: '1.91:1'
        },
        recommendations: {
            fileExtension: ['jpg', 'png'],
            minWidth: 1080,
            minHeight: 1080,
            aspectRatio: '1:1'
        }
    },
    {
        title: 'Horizontal video',
        identifier: 'horizontal_video',
        searchIndex: 'horizontal, video',
        channel: 'video',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description: 'Default horizontal video',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 640,
            minHeight: 480,
            minAspectRatio: '4:3'
        },
        recommendations: {
            width: 1920,
            height: 1080,
            aspectRatio: '16:9'
        }
    },
    {
        title: 'Vertical video',
        identifier: 'vertical_video',
        searchIndex: 'vertical, video',
        channel: 'video',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description: 'Vertical video',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 640,
            maxAspectRatio: '2:3'
        },
        recommendations: {
            width: 1080,
            height: 1920,
            aspectRatio: '9:16'
        }
    },
    {
        title: 'Square video',
        identifier: 'square_video',
        searchIndex: 'square, video',
        channel: 'video',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description: 'Square video',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 480,
            maxAspectRatio: '1:1'
        },
        recommendations: {
            width: 1080,
            height: 1080
        }
    },
    {
        title: 'Youtube horizontal video',
        identifier: 'youtube_horizontal_video',
        searchIndex: 'google, youtube, horizontal, video',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description: 'Youtube horizontal video',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 640,
            minHeight: 480,
            minAspectRatio: '4:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1920,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '16:9'
        }
    },
    {
        title: 'Youtube vertical video',
        identifier: 'youtube_vertical_video',
        searchIndex: 'google, youtube, vertical, video',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description: 'Youtube vertical video',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 640,
            maxAspectRatio: '2:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1920,
            maxSizeKb: 256000000,
            aspectRatio: '9:16'
        }
    },
    {
        title: 'Youtube square video',
        identifier: 'youtube_square_video',
        searchIndex: 'google, youtube, square, video',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description: 'Youtube square video',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 480
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '1:1'
        }
    },
    {
        title: 'Youtube thumbnail for video',
        identifier: 'youtube_thumbnail_video',
        searchIndex: 'google, youtube, thumbnail, video',
        channel: 'video',
        platform: 'youtube',
        assetType: 'image',
        fileExtension: ['jpg', 'gif', 'png'],
        description: 'Youtube square video',
        restrictions: {
            fileExtension: ['jpg', 'gif', 'png'],
            minWidth: 1280,
            minHeight: 640,
            maxSizeKb: 2000
        },
        recommendations: {
            fileExtension: ['jpg', 'gif', 'png'],
            width: 1280,
            height: 780,
            aspectRatio: '16:9'
        }
    },
    {
        title: 'Youtube thumbnail for podcast',
        identifier: 'youtube_thumbnail_podcast',
        searchIndex: 'google, youtube, thumbnail, podcast',
        channel: 'video',
        platform: 'youtube',
        assetType: 'image',
        fileExtension: ['jpg', 'gif', 'png'],
        description: 'Youtube square video',
        restrictions: {
            fileExtension: ['jpg', 'gif', 'png'],
            minWidth: 1280,
            minHeight: 640,
            maxSizeKb: 10000
        },
        recommendations: {
            fileExtension: ['jpg', 'gif', 'png'],
            width: 1280,
            height: 780,
            aspectRatio: '16:9'
        }
    },
    {
        title: 'Youtube companion banners',
        identifier: 'youtube_companion_banner',
        searchIndex: 'google, youtube, companion, banner',
        channel: 'video',
        platform: 'youtube',
        assetType: 'image',
        fileExtension: ['jpg', 'gif', 'png'],
        description: 'Youtube square video',
        recommendations: {
            fileExtension: ['jpg', 'gif', 'png'],
            height: 300,
            width: 60,
            maxSizeKb: 150
        }
    },
    {
        title: 'Youtube skippable in-stream horizontal video(Awareness & Action)',
        identifier: 'youtube_skippable_in_stream_horizontal_video_awareness_action',
        searchIndex: 'google, youtube, skippable, stream, in-stream, horizontal, video, awareness, action',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'Skippable in-stream ads play before, during, or after other videos on YouTube and websites and apps on Google video partners. After 5 seconds, the viewer has the option to skip the ad and continue watching the video.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 640,
            minHeight: 480,
            minAspectRatio: '4:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1920,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '16:9',
            maxDuration: 20,
            minDuration: 15
        }
    },
    {
        title: 'Youtube skippable in-stream vertical video(Awareness & Action)',
        identifier: 'youtube_skippable_in_stream_vertical_video_awareness_action',
        searchIndex: 'google, youtube, skippable, stream, in-stream, vertical, video, awareness, action',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'Skippable in-stream ads play before, during, or after other videos on YouTube and websites and apps on Google video partners. After 5 seconds, the viewer has the option to skip the ad and continue watching the video.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 640,
            maxAspectRatio: '2:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1920,
            maxSizeKb: 256000000,
            aspectRatio: '9:16',
            minDuration: 15,
            maxDuration: 20
        }
    },
    {
        title: 'Youtube skippable in-stream square video(Awareness & Action)',
        identifier: 'youtube_skippable_in_stream_square_video_awareness_action',
        searchIndex: 'google, youtube, skippable, stream, in-stream, square, video, awareness, action',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'Skippable in-stream ads play before, during, or after other videos on YouTube and websites and apps on Google video partners. After 5 seconds, the viewer has the option to skip the ad and continue watching the video.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 480
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '1:1',
            minDuration: 15,
            maxDuration: 20
        }
    },
    {
        title: 'Youtube skippable in-stream horizontal video (Consideration)',
        identifier: 'youtube_skippable_in_stream_horizontal_video_consideration',
        searchIndex: 'google, youtube, skippable, stream, in-stream, horizontal, video, consideration',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'Skippable in-stream ads play before, during, or after other videos on YouTube and websites and apps on Google video partners. After 5 seconds, the viewer has the option to skip the ad and continue watching the video.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 640,
            minHeight: 480,
            minAspectRatio: '4:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1920,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '16:9',
            maxDuration: 180,
            minDuration: 120
        }
    },
    {
        title: 'Youtube skippable in-stream vertical video(Consideration)',
        identifier: 'youtube_skippable_in_stream_vertical_video_consideration',
        searchIndex: 'google, youtube, skippable, stream, in-stream, vertical, video, Consideration',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'Skippable in-stream ads play before, during, or after other videos on YouTube and websites and apps on Google video partners. After 5 seconds, the viewer has the option to skip the ad and continue watching the video.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 640,
            maxAspectRatio: '2:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1920,
            maxSizeKb: 256000000,
            aspectRatio: '9:16',
            minDuration: 120,
            maxDuration: 180
        }
    },
    {
        title: 'Youtube skippable in-stream square video(Consideration)',
        identifier: 'youtube_skippable_in_stream_square_video_consideration',
        searchIndex: 'google, youtube, skippable, stream, in-stream, square, video, consideration',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'Skippable in-stream ads play before, during, or after other videos on YouTube and websites and apps on Google video partners. After 5 seconds, the viewer has the option to skip the ad and continue watching the video.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 480
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '1:1',
            minDuration: 120,
            maxDuration: 180
        }
    },
    {
        title: 'Youtube non-skippable in-stream horizontal video',
        identifier: 'youtube_non_skippable_in_stream_horizontal_video',
        searchIndex: 'google, youtube, non-skippable, skippable, stream, in-stream, horizontal, video',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            "Non-skippable in-stream ads play before, during, or after other videos. Viewers don't have the option to skip the ad. Non-skippable in-stream ads are 15-seconds (or shorter).",
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 640,
            minHeight: 480,
            minAspectRatio: '4:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1920,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '16:9',
            maxDuration: 15
        }
    },
    {
        title: 'Youtube non-skippable in-stream vertical video',
        identifier: 'youtube_non_skippable_in_stream_vertical_video',
        searchIndex: 'google, youtube, non-skippable, skippable, stream, in-stream, vertical, video',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            "Non-skippable in-stream ads play before, during, or after other videos. Viewers don't have the option to skip the ad. Non-skippable in-stream ads are 15-seconds (or shorter).",
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 640,
            maxAspectRatio: '2:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1920,
            maxSizeKb: 256000000,
            aspectRatio: '9:16',
            maxDuration: 15
        }
    },
    {
        title: 'Youtube non-skippable in-stream square video',
        identifier: 'youtube_non_skippable_in_stream_square_video',
        searchIndex: 'google, youtube, non-skippable, skippable, stream, in-stream, square, video',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            "Non-skippable in-stream ads play before, during, or after other videos. Viewers don't have the option to skip the ad. Non-skippable in-stream ads are 15-seconds (or shorter).",
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 480
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '1:1',
            maxDuration: 15
        }
    },
    {
        title: 'Youtube in-feed horizontal video(Awareness)',
        identifier: 'youtube_in_feed_horizontal_video_awareness',
        searchIndex: 'google, youtube, in-feed, feed, horizontal, video, awareness',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'In-feed video ads place your brand, product, or service alongside YouTube content that is likely to be viewed by your target audience. In-feed video ads appear in YouTube search results, YouTube watch next, and the YouTube app Home feed.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 640,
            minHeight: 480,
            minAspectRatio: '4:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1920,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '16:9',
            maxDuration: 20,
            minDuration: 15
        }
    },
    {
        title: 'Youtube in-feed vertical video(Awareness)',
        identifier: 'youtube_in_feed_vertical_video_awareness',
        searchIndex: 'google, youtube, feed, in-feed, vertical, video, awareness',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'In-feed video ads place your brand, product, or service alongside YouTube content that is likely to be viewed by your target audience. In-feed video ads appear in YouTube search results, YouTube watch next, and the YouTube app Home feed.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 640,
            maxAspectRatio: '2:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1920,
            maxSizeKb: 256000000,
            aspectRatio: '9:16',
            minDuration: 15,
            maxDuration: 20
        }
    },
    {
        title: 'Youtube in-feed square video(Awareness)',
        identifier: 'youtube_in_feed_square_video_awareness',
        searchIndex: 'google, youtube, feed, in-feed, square, video, awareness',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'In-feed video ads place your brand, product, or service alongside YouTube content that is likely to be viewed by your target audience. In-feed video ads appear in YouTube search results, YouTube watch next, and the YouTube app Home feed.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 480
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '1:1',
            minDuration: 15,
            maxDuration: 20
        }
    },
    {
        title: 'Youtube in-stream horizontal video (Consideration)',
        identifier: 'youtube_in_feed_horizontal_video_consideration',
        searchIndex: 'google, youtube, stream, in-feed, horizontal, video, consideration',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'In-feed video ads place your brand, product, or service alongside YouTube content that is likely to be viewed by your target audience. In-feed video ads appear in YouTube search results, YouTube watch next, and the YouTube app Home feed.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 640,
            minHeight: 480,
            minAspectRatio: '4:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1920,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '16:9',
            maxDuration: 180,
            minDuration: 120
        }
    },
    {
        title: 'Youtube in-feed vertical video(Consideration)',
        identifier: 'youtube_in_feed_vertical_video_consideration',
        searchIndex: 'google, youtube, feed, in-feed, vertical, video, consideration',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'In-feed video ads place your brand, product, or service alongside YouTube content that is likely to be viewed by your target audience. In-feed video ads appear in YouTube search results, YouTube watch next, and the YouTube app Home feed.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 640,
            maxAspectRatio: '2:3'
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1920,
            maxSizeKb: 256000000,
            aspectRatio: '9:16',
            minDuration: 120,
            maxDuration: 180
        }
    },
    {
        title: 'Youtube in-feed square video(Consideration)',
        identifier: 'youtube_in_feed_square_video_consideration',
        searchIndex: 'google, youtube, feed, in-feed, square, video, consideration',
        channel: 'video',
        platform: 'youtube',
        assetType: 'video',
        fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc'],
        description:
            'In-feed video ads place your brand, product, or service alongside YouTube content that is likely to be viewed by your target audience. In-feed video ads appear in YouTube search results, YouTube watch next, and the YouTube app Home feed.',
        restrictions: {
            fileExtension: ['wmv', 'avi', 'mov', 'flv', 'mpeg', 'mp4', '3gp', 'webm', 'hevc', 'm2v', 'm4v'],
            minWidth: 480,
            minHeight: 480
        },
        recommendations: {
            fileExtension: ['m2v', 'm4v'],
            width: 1080,
            height: 1080,
            maxSizeKb: 256000000,
            aspectRatio: '1:1',
            minDuration: 120,
            maxDuration: 180
        }
    },
    {
        title: 'Facebook feed video',
        identifier: 'facebook_feed_video',
        searchIndex: 'meta, facebook, feed, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'Use the video format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minVideoDuration: 1,
            maxVideoDuration: 14460
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,

            compression: 'h264',
            bitRate: 128000,
            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook in-stream video(Desktop)',
        identifier: 'facebook_in_stream_video_desktop',
        searchIndex: 'meta, facebook, stream, desktop, in-stream, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'The in-stream video placement will play video ads that are 15 seconds or less for the full duration of the video. The ads may also be served as skippable, which allows the user to skip the ad after the first 5 seconds. Video ads that are longer than 15 seconds will show the first 15 seconds and then truncate. Viewers will be able to resume watching the ad in its entirety by clicking on the context card that appears below the main video after ad truncation.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minVideoDuration: 5,
            maxVideoDuration: 15
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',

            aspectRatio: '16:9',
            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook in-stream video(Mobile)',
        identifier: 'facebook_in_stream_video_mobile',
        searchIndex: 'meta, facebook, stream, mobile, in-stream, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'The in-stream video placement will play video ads that are 15 seconds or less for the full duration of the video. The ads may also be served as skippable, which allows the user to skip the ad after the first 5 seconds. Video ads that are longer than 15 seconds will show the first 15 seconds and then truncate. Viewers will be able to resume watching the ad in its entirety by clicking on the context card that appears below the main video after ad truncation.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            maxSizeKb: 4000000,
            minVideoDuration: 5,
            maxVideoDuration: 600
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '1:1',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook feeds video',
        identifier: 'facebook_video_feeds',
        searchIndex: 'meta, facebook, feeds, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'The in-stream video placement will play video ads that are 15 seconds or less for the full duration of the video. The ads may also be served as skippable, which allows the user to skip the ad after the first 5 seconds. Video ads that are longer than 15 seconds will show the first 15 seconds and then truncate. Viewers will be able to resume watching the ad in its entirety by clicking on the context card that appears below the main video after ad truncation.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minVideoDuration: 1,
            maxVideoDuration: 14460
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '4:5',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook stories video',
        identifier: 'facebook_sotries_video',
        searchIndex: 'meta, facebook, stories, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in the Video Feed placement to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 500,
            maxSizeKb: 4000000,
            minVideoDuration: 1,
            maxVideoDuration: 120
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '9:16',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook search results video',
        identifier: 'facebook_search_results_video',
        searchIndex: 'meta, facebook, search, results, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'The in-stream video placement will play video ads that are 15 seconds or less for the full duration of the video. The ads may also be served as skippable, which allows the user to skip the ad after the first 5 seconds. Video ads that are longer than 15 seconds will show the first 15 seconds and then truncate. Viewers will be able to resume watching the ad in its entirety by clicking on the context card that appears below the main video after ad truncation.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            minVideoDuration: 1,
            maxVideoDuration: 14460
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '1:1',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook business explore video(Desktop)',
        identifier: 'facebook_business_explore_video_desktop',
        searchIndex: 'meta, facebook, business, desktop, explore, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in Facebook Business Explore to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            minVideoDuration: 1,
            maxVideoDuration: 14460
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '1:1',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook business explore video(mobile)',
        identifier: 'facebook_business_explore_video_mobile',
        searchIndex: 'meta, facebook, business, mobile, explore, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in Facebook Business Explore to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            minVideoDuration: 1,
            maxVideoDuration: 14460
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '4:5',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook reels video',
        identifier: 'facebook_reels_video',
        searchIndex: 'meta, facebook, reels, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'These fullscreen vertical video ads will be inserted in between organic Reels content. Similar to organic Reels, people can comment, like, view, save, share and skip ads. Reels ads will be surfaced in the most popular places to access Reels content. Once a user taps into a Reel from Feed or Reels Tab, they will be launched into a viewer that exclusively shows Reels that scroll vertically.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            aspectRatio: '9:16',
            maxSizeKb: 40000
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '500:88',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Instagram profile feed video',
        identifier: 'instagram_profile_feed_video',
        searchIndex: 'meta, instagram, profile, feed, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in Instagram profile feed to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 500,
            minHeight: 500,
            maxSizeKb: 4000000,
            minAspectRatio: '4:5',
            maxAspectRatio: '1.91:1',
            minVideoDuration: 1,
            maxVideoDuration: 7200
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '4:5',
            minAspectRatio: '4:5',
            maxAspectRatio: '1.91:1',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Instagram feed video',
        identifier: 'instagram_feed_video',
        searchIndex: 'meta, instagram, feed, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in Instagram profile feed to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 500,
            maxSizeKb: 4000000,
            minVideoDuration: 1,
            maxVideoDuration: 3600
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '4:5',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Instagram stories video',
        identifier: 'instagram_stories_video',
        searchIndex: 'meta, instagram, stories, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'Instagram Stories will play video ads that are less than 30 seconds for the full duration of the video. Video ads that are 15 seconds or longer may be split into separate Stories cards. Instagram will display either 1, 2, or 3 cards automatically before giving viewers the option to tap ‘Keep Watching’ to see the remainder of the video. By default, the number of cards automatically displayed before prompting viewers to tap Keep Watching is tailored to each viewer.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 500,
            maxSizeKb: 4000000,
            minAspectRatio: '4:5',
            maxAspectRatio: '16:9',
            minVideoDuration: 1,
            maxVideoDuration: 3600
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '9:16',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Instagram explore video',
        identifier: 'instagram_explore_video',
        searchIndex: 'meta, instagram, explore, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in Instagram Explore to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story. Your ads in Instagram Explore will appear on in the same format as your ads in Instagram Feed.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 500,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '191:100',
            minVideoDuration: 1,
            maxVideoDuration: 3600
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '9:16',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Audience Network Native, Banner and Interstitial video',
        identifier: 'meta_audience_network_native_banner_and_interstitial_video',
        searchIndex: 'meta, audience, network, native, banner, interstitial, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in Audience Network to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            maxDuration: 120,
            minDuration: 1
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '9:16',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Audience Network Rewarded video',
        identifier: 'meta_audience_network_rewarder_video',
        searchIndex: 'meta, audience, network, rewarded, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in Audience Network to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            maxDuration: 61,
            minDuration: 3
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '9:16',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Messenger stories video',
        identifier: 'meta_messanger_stories_video',
        searchIndex: 'meta, messenger, stories, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'You can use the video format in Audience Network to show off your product, service or brand in new ways. Include movement and sound to capture attention quickly and show unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 500,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '191:100',
            maxDuration: 120,
            minDuration: 1
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '9:16',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook right column video(desktop/mobile)',
        identifier: 'facebook_right_column_video_desktop_mobile',
        searchIndex: 'meta, facebook, right, column, desktop, mobile, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'Use the video format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            maxDuration: 14460,
            minDuration: 1
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '1:1',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook right column video(mobile)',
        identifier: 'facebook_right_column_video_mobile',
        searchIndex: 'meta, facebook, right, column, mobile, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'Use the video format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            maxDuration: 14460,
            minDuration: 1
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '4:5',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook group feed video(desktop/mobile)',
        identifier: 'facebook_group_feed_video_desktop_mobile',
        searchIndex: 'meta, facebook, right, column, desktop, mobile, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'Use the video format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            maxDuration: 14460,
            minDuration: 1
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '1:1',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Facebook group feed video(mobile)',
        identifier: 'facebook_group_feed_video_mobile',
        searchIndex: 'meta, facebook, group, feed, mobile, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'Use the video format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            maxDuration: 14460,
            minDuration: 1
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '4:5',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Instagram shop video(desktop/mobile)',
        identifier: 'instagram_shop_video_desktop_mobile',
        searchIndex: 'meta, instagram, shop, desktop, mobile, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'Use the video format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            maxDuration: 14460,
            minDuration: 1
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '1:1',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'Instagram shop video(mobile)',
        identifier: 'instagram_shop_video_mobile',
        searchIndex: 'meta, instagram, shop, mobile, video',
        channel: 'social',
        platform: 'meta',
        assetType: 'video',
        fileExtension: ['mp4', 'mov', 'gif'],
        description:
            'Use the video format in Feed to show off your product, service or brand in new ways. To capture attention quickly, use movement and sound to show the unique features of a product or tell your brand story.',
        restrictions: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 120,
            minHeight: 120,
            maxSizeKb: 4000000,
            minAspectRatio: '9:16',
            maxAspectRatio: '16:9',
            maxDuration: 14460,
            minDuration: 1
        },
        recommendations: {
            fileExtension: ['mp4', 'mov', 'gif'],
            minWidth: 1080,
            minHeight: 1080,
            audioRequired: true,
            compression: 'h264',
            aspectRatio: '4:5',

            audioBitRate: 128000,
            audioChannelLayout: 'stereo',
            audioCompression: 'aac'
        }
    },
    {
        title: 'App campaigns | Square: 1200x1200',
        identifier: 'ga_app_square_1200x1200',
        searchIndex: 'display, app, campaigns, square, 1200',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Square: 1200x1200',
        restrictions: {
            fileExtension: ['zip'],
            height: 1200,
            width: 1200
        }
    },
    {
        title: 'App campaigns | Landscape: 1200x628',
        identifier: 'ga_app_landscape_1200x628',
        searchIndex: 'display, app, campaigns, landscape, 1200, 628',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Landscape: 1200x628',
        restrictions: {
            fileExtension: ['zip'],
            width: 1200,
            height: 628
        }
    },
    {
        title: 'App campaigns | Portrait: 1200x1500',
        identifier: 'ga_app_portrait_1200x1500',
        searchIndex: 'display, app, campaigns, portrait, 1200, 1500',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Portrait: 1200x15000',
        restrictions: {
            fileExtension: ['zip'],
            width: 1200,
            height: 1500
        }
    },

    {
        title: 'Billboard: 970x250px',
        identifier: 'billboard',
        searchIndex: 'display, billboard, 970, 250',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Billboard',
        restrictions: {
            fileExtension: ['zip'],
            width: 970,
            height: 250
        }
    },
    {
        title: 'Billboard: 600x150px',
        identifier: 'billboard_small',
        searchIndex: 'display, billboard, 600, 150',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Billboard small',
        restrictions: {
            fileExtension: ['zip'],
            width: 600,
            height: 150
        }
    },
    {
        title: 'Mobile banner: 300x50px',
        identifier: 'mobile_banner',
        searchIndex: 'display, mobile, banner, 300, 50',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Mobile banner',
        restrictions: {
            fileExtension: ['zip'],
            width: 300,
            height: 50
        }
    },
    {
        title: 'Square: 250x250px',
        identifier: 'square',
        searchIndex: 'display, square, 250, 250',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Square',
        restrictions: {
            fileExtension: ['zip'],
            width: 250,
            height: 250
        }
    },
    {
        title: 'Large Mobile: 320x100px',
        identifier: 'largemobile',
        searchIndex: 'display, large, mobile, 320, 100',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Large Mobile',
        restrictions: {
            fileExtension: ['zip'],
            width: 320,
            height: 100
        }
    },
    {
        title: 'Inline Rectangle: 300x250px',
        identifier: 'inlinerectangle',
        searchIndex: 'display, inline, rectangle, 300, 250',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Inline Rectangle',
        restrictions: {
            fileExtension: ['zip'],
            width: 300,
            height: 250
        }
    },
    {
        title: 'Skyscraper: 120x600px',
        identifier: 'skyscraper',
        searchIndex: 'display, skyscraper, 120, 600',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Skyscraper',
        restrictions: {
            fileExtension: ['zip'],
            width: 120,
            height: 600
        }
    },
    {
        title: 'Wide Skyscraper: 160x600px',
        identifier: 'wide_skyscraper',
        searchIndex: 'display, wide, skyscraper, 160, 600',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Wide Skyscraper',
        restrictions: {
            fileExtension: ['zip'],
            width: 160,
            height: 600
        }
    },
    {
        title: 'Small Square: 200x200px',
        identifier: 'smallsquare',
        searchIndex: 'display, small, square, 200, 200',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Small Square',
        restrictions: {
            fileExtension: ['zip'],
            width: 200,
            height: 200
        }
    },
    {
        title: 'Half page: 300x600px',
        identifier: 'halfpage',
        searchIndex: 'display, half, page, 300, 600',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Half page',
        restrictions: {
            fileExtension: ['zip'],
            width: 300,
            height: 600
        }
    },
    {
        title: 'Banner large: 600x74px',
        identifier: 'banner_large',
        searchIndex: 'display, banner, large, 600, 74',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Banner large',
        restrictions: {
            fileExtension: ['zip'],
            width: 600,
            height: 74
        }
    },
    {
        title: 'Banner Xlarge: 600x150px',
        identifier: 'banner_xlarge',
        searchIndex: 'display, banner, xlarge, 600, 150',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Banner Xlarge',
        restrictions: {
            fileExtension: ['zip'],
            width: 600,
            height: 150
        }
    },
    {
        title: 'Full banner: 468x60px',
        identifier: 'full_banner',
        searchIndex: 'display, banner, full, 468, 60',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Banner Xlarge',
        restrictions: {
            fileExtension: ['zip'],
            width: 468,
            height: 60
        }
    },
    {
        title: 'Large rectangle: 336x280px',
        identifier: 'large_rectangle',
        searchIndex: 'display, large, rectangle, 336, 280',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Large rectangle',
        restrictions: {
            fileExtension: ['zip'],
            width: 336,
            height: 280
        }
    },
    {
        title: 'Leaderboard: 468x60px',
        identifier: 'leaderboard_xsmall',
        searchIndex: 'display, leaderboard, xsmall, 468, 60',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Leaderboard',
        restrictions: {
            fileExtension: ['zip'],
            width: 468,
            height: 60
        }
    },
    {
        title: 'Leaderboard: 600x74px',
        identifier: 'leaderboard_small',
        searchIndex: 'display, leaderboard, small, 600, 74',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Leaderboard',
        restrictions: {
            fileExtension: ['zip'],
            width: 600,
            height: 74
        }
    },
    {
        title: 'Leaderboard: 728x90px',
        identifier: 'leaderboard',
        searchIndex: 'display, leaderboard, 728, 90',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Leaderboard',
        restrictions: {
            fileExtension: ['zip'],
            width: 728,
            height: 90
        }
    },
    {
        title: 'Leaderboard large: 940x100px',
        identifier: 'leaderboard_large',
        searchIndex: 'display, leaderboard, large, 940, 100',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Leaderboard',
        restrictions: {
            fileExtension: ['zip'],
            width: 940,
            height: 100
        }
    },
    {
        title: 'Leaderboard large: 1360x130px',
        identifier: 'leaderboard_xlarge',
        searchIndex: 'display, leaderboard, xlarge, 1360, 130',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Leaderboard',
        restrictions: {
            fileExtension: ['zip'],
            width: 1360,
            height: 130
        }
    },
    {
        title: 'Medium Rectangle: 300x250px',
        identifier: 'medium_rectangle',
        searchIndex: 'display, medium, rectangle, 300, 250',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Medium Rectangle',
        restrictions: {
            fileExtension: ['zip'],
            width: 300,
            height: 250
        }
    },
    {
        title: 'Mobile: 320x50px',
        identifier: 'mobile',
        searchIndex: 'display, medium, rectangle, 320, 50',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Mobile',
        restrictions: {
            fileExtension: ['zip'],
            width: 320,
            height: 50
        }
    },
    {
        title: 'Large mobile: 320x240px',
        identifier: 'large_mobile',
        searchIndex: 'display, large, mobile, 320, 240',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Large mobile',
        restrictions: {
            fileExtension: ['zip'],
            width: 320,
            height: 240
        }
    },
    {
        title: 'Mobile interstitial: 320x480',
        identifier: 'mobile_interstitial',
        searchIndex: 'display, mobile, interstitial, 320, 480',
        channel: 'display',
        platform: 'multi',
        assetType: 'zip',
        fileExtension: ['zip'],
        description: 'Mobile interstitial',
        restrictions: {
            fileExtension: ['zip'],
            width: 320,
            height: 480
        }
    }

    // {
    //     key: 'meta_linkAd',
    //     channel: 'social',
    //     title: 'Meta image ad',
    //     width: 600,
    //     height: 600,
    //     description: 'Meta image ad',
    //     icon: '',
    //     fileExtension: ['JPG', 'PNG'],
    //     minWidth: 600,
    //     minHeight: 600,
    //     minResolution: '1080x1080',
    //     minAspectRatio: '1.91:1',
    //     maxAspectRatio: '4:5',
    //     imageSizeMb: 30,
    //     aspectRatioPercentageTolerance: 3
    // },
    // {
    //     key: 'meta_videoAd',
    //     channel: 'social',
    //     title: 'Meta video ad',
    //     width: 120,
    //     height: 120,
    //     description: 'Meta video ad',
    //     icon: '',
    //     fileExtension: ['MP4', 'MOV', 'GIF'],
    //     minWidth: 120,
    //     minHeight: 120,
    //     minResolution: '1080x1080',
    //     minAspectRatio: '16:9',
    //     maxAspectRatio: '9:16',
    //     videoSizeMb: 4000,
    //     minVideoDuration: 1,
    //     maxVideoDuration: 14400,
    //     aspectRatioPercentageTolerance: 3
    // },
    // {
    //     key: 'meta_carousel',
    //     channel: 'social',
    //     title: 'Meta carousel ad',
    //     width: 120,
    //     height: 120,
    //     description: 'Meta video ad',
    //     icon: '',
    //     fileExtension: ['JPG', 'PNG', 'MP4', 'MOV', 'GIF'],
    //     minResolution: '1080x1080',
    //     minAspectRatio: '1.91:1',
    //     maxAspectRatio: '1:1',
    //     videoSizeMb: 4000,
    //     imageSizeMb: 30,
    //     minVideoDuration: 1,
    //     maxVideoDuration: 14400,
    //     aspectRatioPercentageTolerance: 3
    // },
    // {
    //     key: 'meta_carousel',
    //     channel: 'social',
    //     title: 'Meta stories ad',
    //     width: 120,
    //     height: 120,
    //     description: 'Meta video ad',
    //     icon: '',
    //     fileExtension: ['JPG', 'PNG', 'MP4', 'MOV', 'GIF'],
    //     minWidth: 500,
    //     minResolution: '1080x1080',
    //     minAspectRatio: '1.91:1',
    //     maxAspectRatio: '9:16',
    //     videoSizeMb: 4000,
    //     imageSizeMb: 30,
    //     minVideoDuration: 1,
    //     maxVideoDuration: 14400,
    //     aspectRatioPercentageTolerance: 1
    // }
];

export default allPresets;
