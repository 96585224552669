const emptyItemTemplates = {
    brick: {
        title: '',
        subType: '',
        status: '',
        data: {}
    }
};

export default emptyItemTemplates;
