import Resources from 'components/data/Resources/components';
import { TableColumn } from 'components/bricks/types/tableColumn.type';
import ComponentStore from 'components/data/ComponentStore';
import cloneDeep from 'helpers/cloneDeep';
import { BrickChannel, BrickSocialPlatform, BrickSubType } from '../types/brick.type';
import { IEditorBlock } from '../types/editorBlock.type';
import { ActionType } from '../types/bricksComponentStore.type';

export interface CustomerConfig {
    availableBricks?: ConfigAvailableBricks[];
    overview?: ConfigOverview;
    bricks?: ConfigBricks;
    settings?: ConfigSettings;
}

interface ConfigAvailableBricks {
    channel?: BrickChannel;
    platform?: BrickSocialPlatform;
    subType?: BrickSubType;
}

interface ConfigOverview {
    customColumns?: {
        view: ActionType[];
        columns: TableColumn[];
    };
}

interface ConfigBricks {
    interfaceSetup?: ConfigInterfaceSetup[];
    customTabs?: any;
    defaultData?: any;
}

interface ConfigSettings {
    enableRequest?: boolean;
}

interface ConfigInterfaceSetup {
    subType?: BrickSubType[];
    tab?: string;
    newTab?: boolean;
    afterTab?: string;
    removeFromInterfaceSetup?: string[];
    addToInterfaceSetup?: IEditorBlock[];
}

const getCustomerConfig = () => {
    // Set this to false to use the test config (e.g. on localhost). If true, it uses the config from the resources
    const useSetupConfig = true;

    if (!useSetupConfig) return customerConfig;

    // Get the default config from the resources, then overwrite it with the campaignFormat specific config
    const config: CustomerConfig = cloneDeep(Resources.get('setup_bricks') ?? {});
    const campaignCustomerConfig = cloneDeep(ComponentStore.get('Bricks')?.campaignCustomerConfig ?? {});

    Object.keys(campaignCustomerConfig).forEach((key) => {
        config[key] = campaignCustomerConfig[key];
    });

    // TEMPORARY: Only show finished bricks
    // config.availableBricks = [{ channel: 'assets' }, { channel: 'social', platform: 'meta' }, { channel: 'display', platform: 'cm360' }];

    return config;
};

const customerConfig: CustomerConfig = {
    availableBricks: [
        // { channel: 'assets' },
        // { channel: 'social', platform: 'multi' },
        // { channel: 'social', platform: 'meta' },
        // { channel: 'social', platform: 'tiktok' }
    ],
    overview: {
        customColumns: {
            view: ['create'],
            columns: [
                {
                    identifier: 'budget',
                    type: 'text',
                    title: 'Budget',
                    model: 'data.budget',
                    icon: 'euro'
                }
            ]
        }
    },
    bricks: {
        defaultData: [
            {
                subType: ['single_asset'],
                data: {
                    title: 'Default title from customer config'
                }
            }
        ],
        interfaceSetup: [
            {
                subType: ['meta_campaign'],
                tab: 'settings',
                removeFromInterfaceSetup: ['single_asset-descriptionz'],
                addToInterfaceSetup: []
            }
        ],
        customTabs: [
            {
                subType: [],
                key: 'customTab',
                customTab: true,
                title: 'Custom tab',
                afterTab: 'publish',
                type: 'multiInput',
                items: [
                    {
                        title: 'Trafficking setup',
                        path: 'publish',
                        items: [
                            {
                                type: 'subSection',
                                opened: true,
                                title: 'Ad Details',
                                items: [
                                    {
                                        type: 'text',
                                        model: 'publish.placement',
                                        tooltip: 'Please insert placement name.',
                                        placeholder: "e.g. 'Klima'",
                                        label: 'Placement name'
                                    },
                                    {
                                        type: 'select',
                                        model: 'publish.targeting',
                                        label: 'Targeting',
                                        options: {
                                            '1825': '18-65',
                                            '': 'Select...'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
};

export default getCustomerConfig;
