export const RestrictionObjects = {
    minWidth: {
        message: 'The width is below the minimum',
        unit: 'px',
        restriction: 'minWidth',
        name: 'Minimum width'
    },
    width: {
        message: 'The width is not the same',
        unit: 'px',
        restriction: 'width',
        name: 'Width'
    },
    maxWidth: {
        message: 'Maximum width has exceeded',
        unit: 'px',
        restriction: 'maxWidth',
        name: 'Maximum width'
    },
    minHeight: {
        message: 'The height is below the minimum',
        unit: 'px',
        restriction: 'minHeight',
        name: 'Minimum height'
    },
    height: {
        message: 'The height is not the same',
        unit: 'px',
        restriction: 'height',
        name: 'Height'
    },
    maxHeight: {
        message: 'Maximum height has exceeded',
        unit: 'px',
        restriction: 'maxHeight',
        name: 'Maximum height'
    },
    invalidAsset: {
        message: 'Invalid asset',
        restriction: 'invalidAsset',
        name: 'Invalid asset'
    },
    maxSize: {
        message: 'The maximum file size is exceeded',
        unit: 'kb',
        restriction: 'maxSize',
        name: 'Max size'
    },
    fileType: {
        message: 'File type problem',
        restriction: 'fileType',
        name: 'File type'
    },
    fileExtension: {
        message: 'File extension problem',
        restriction: 'fileExtension',
        name: 'File extension'
    },
    minDuration: {
        message: 'File duration is below the minimum',
        unit: 's',
        restriction: 'minDuration',
        name: 'Minimum duration'
    },
    maxDuration: {
        message: 'File exceeds maximum duration',
        unit: 's',
        restriction: 'maxDuration',
        name: 'Maximum duration'
    },
    bitRate: {
        message: 'File is below the required bitrate.',
        restriction: 'bitRate',
        name: 'Bit rate'
    },
    zipStructure: {
        message: 'Zip content does not match the required structure',
        restriction: 'zipStructure',
        name: 'Zip structure'
    },
    aspectRatio: {
        message: 'Aspect ratio of the asset is not as the aspect ratio in the specifications',
        restriction: 'aspectRatio',
        name: 'Aspect ratio'
    },
    minAspectRatio: {
        message: 'The aspect ratio of the asset is below the minimum aspect ratio',
        restriction: 'minAspectRatio',
        name: 'Minimum aspect ratio'
    },
    maxAspectRatio: {
        message: 'Aspect ratio of the asset is above the maximum aspect ratio',
        restriction: 'maxAspectRatio',
        name: 'Maximum aspect ratio'
    },
    compression: {
        message: 'The compression should be different',
        restriction: 'compression',
        name: 'Compression'
    },
    channelLayout: {
        message: 'The channel layout should be different',
        restriction: 'channelLayout',
        name: 'Channel layout'
    },
    audioRequired: {
        message: 'Audio is required',
        restriction: 'audioRequired',
        name: 'Audtio required'
    },
    audioBitRate: {
        message: 'Audio bit rate is below the minimum',
        restriction: 'audioBitRate',
        name: 'Audio bit rate'
    },
    audioChannelLayout: {
        message: 'Audio channel layout should be different',
        restriction: 'audioChannelLayout',
        name: 'Audio channel layout'
    },
    audioCompression: {
        message: 'Audio compression should be different',
        restriction: 'audioCompression',
        name: 'Autio compression'
    },

    audioMaxDuration: {
        message: 'Audio max duration exceeds the maximum',
        restriction: 'audioMaxDuration',
        name: 'Audio max duration'
    },
    audioMinDuration: {
        message: 'Audio duration is below the minimum',
        restriction: 'audioMinDuration',
        name: 'Audio min duration'
    }
};
