import { AssetMetadata } from 'components/bricks/helpers/asset.helpers';
import { FileExt } from 'components/bricks/types/brick.type';
import { AssetRestrictionsKeys } from 'components/bricks/types/preset';
import { AssetPresetValidator } from '../helpers/asset-preset-validator.helpers';

type RestrictionCheckFunction = (data: AssetMetadata, restriction: any) => boolean;

/**
 * This is a map of restriction types with validation functions that is used
 * to validate the asset data based on certain conditions.
 */
const restrictionConditionMap: Partial<Record<AssetRestrictionsKeys, RestrictionCheckFunction>> = {
    fileExtension: (data, fileExtensions: FileExt[]) =>
        data.fileExtension ? !AssetPresetValidator.isFileExtensionValid(data.fileExtension, fileExtensions) : false,
    width: (data, width: number) => !(data?.width && data.width === width),
    minWidth: (data, minWidth: number) => !(data?.width && data.width >= minWidth),
    maxWidth: (data, maxWidth: number) => !(data?.width && data.width <= maxWidth),
    height: (data, height: number) => !(data?.height && data.height === height),
    minHeight: (data, minHeight: number) => !(data?.height && data.height >= minHeight),
    maxHeight: (data, maxHeight: number) => !(data?.height && data.height <= maxHeight),
    aspectRatio: (data, aspectRatio: string) => !(data.aspectRatio && data.aspectRatio === aspectRatio),
    minAspectRatio: (data, minAspectRatio: string) => !AssetPresetValidator.isAspectRatioValid('minAspectRatio', data.aspectRatio, minAspectRatio),
    maxAspectRatio: (data, maxAspectRatio: string) => !AssetPresetValidator.isAspectRatioValid('maxAspectRatio', data.aspectRatio, maxAspectRatio),
    maxSizeKb: (data, maxSizeKb: number) => !AssetPresetValidator.isFileSizeValid(maxSizeKb, data.filesize),
    maxDuration: (data, maxDuration: number) => !AssetPresetValidator.isMaxDurationValid(maxDuration, data.duration),
    maxVideoDuration: (data, maxVideoDuration: number) => !AssetPresetValidator.isMaxDurationValid(maxVideoDuration, data.duration),
    minDuration: (data, minDuration: number) => !AssetPresetValidator.isMinDurationValid(minDuration, data.duration),
    minVideoDuration: (data, minVideoDuration: number) => !AssetPresetValidator.isMinDurationValid(minVideoDuration, data.duration)
};

export default restrictionConditionMap;
