import { IMultiSocialMapping } from 'components/bricks/types/multiSocialMapping.type';

export const MultiSocialMapping: IMultiSocialMapping = {
    campaign_name: {
        properties: {
            meta: 'name',
            tiktok: 'campaign_name',
            snapchat: 'name'
        }
    },
    objective: {
        unifiedValues: {
            AWARENESS: {
                meta: 'OUTCOME_AWARENESS',
                tiktok: 'REACH',
                snapchat: 'BRAND_AWARENESS'
            },
            TRAFFIC: {
                meta: 'OUTCOME_TRAFFIC',
                tiktok: 'TRAFFIC',
                snapchat: 'WEB_CONVERSION'
            },
            APP_PROMOTION: {
                meta: 'OUTCOME_APP_PROMOTION',
                tiktok: 'APP_PROMOTION',
                snapchat: 'APP_INSTALLS'
            },
            ENGAGEMENT: {
                meta: 'OUTCOME_ENGAGEMENT',
                tiktok: 'ENGAGEMENT',
                snapchat: 'ENGAGEMENT'
            },
            LEADS: {
                meta: 'OUTCOME_LEADS',
                tiktok: 'LEAD_GENERATION',
                snapchat: 'LEAD_GENERATION'
            },
            SALES: {
                meta: 'OUTCOME_SALES',
                tiktok: 'PRODUCT_SALES',
                snapchat: 'CATALOG_SALES'
            }
        },
        properties: {
            meta: 'objective',
            tiktok: 'objective_type',
            snapchat: 'objective'
        }
    },
    campaign_customize_name: {
        properties: {
            meta: 'customize_name',
            tiktok: 'customize_name',
            snapchat: 'customize_name'
        }
    },
    budget_optimize_on: {
        properties: {
            meta: 'budget_optimization_on',
            tiktok: 'budget_optimize_on'
        },
        unifiedValues: {
            true: {
                meta: 'true',
                tiktok: 'true'
            },
            false: {
                meta: 'false',
                tiktok: 'false'
            }
        }
    },
    status: {
        unifiedValues: {
            ACTIVE: {
                meta: 'ACTIVE',
                tiktok: 'ENABLE',
                snapchat: 'ACTIVE'
            },
            PAUSED: {
                meta: 'PAUSED',
                tiktok: 'DISABLE',
                snapchat: 'PAUSED'
            }
        },
        properties: {
            meta: 'status',
            tiktok: 'operation_status',
            snapchat: 'status'
        }
    },
    special_industries: {
        type: 'array',
        properties: {
            meta: 'special_ad_categories.value',
            tiktok: 'special_industries'
        },
        unifiedValues: {
            HOUSING: {
                meta: 'HOUSING',
                tiktok: 'HOUSING'
            },
            EMPLOYMENT: {
                meta: 'EMPLOYMENT',
                tiktok: 'EMPLOYMENT'
            },
            CREDIT: {
                meta: 'CREDIT',
                tiktok: 'CREDIT'
            },
            POLITICS: {
                meta: 'ISSUES_ELECTIONS_POLITICS'
            },
            GAMBLING: {
                meta: 'ONLINE_GAMBLING_AND_GAMING'
            }
        }
    },
    campaign_start_time: {
        properties: {
            meta: 'start_time',
            snapchat: 'start_time'
        }
    },
    campaign_stop_time: {
        properties: {
            meta: 'stop_time',
            snapchat: 'end_time'
        }
    },
    campaign_budget_mode: {
        properties: {
            tiktok: 'budget_mode',
            meta: 'budget_mode'
        },
        unifiedValues: {
            LIFETIME: {
                meta: 'LIFETIME',
                tiktok: 'BUDGET_MODE_TOTAL'
            },
            DAILY: {
                meta: 'DAILY',
                tiktok: 'BUDGET_MODE_DAY'
            }
        }
    },
    campaign_budget: {
        properties: {
            meta: 'budget',
            tiktok: 'budget',
            snapchat: 'lifetime_spend_cap_micro'
        }
    },
    adset_name: {
        properties: {
            meta: 'name',
            tiktok: 'adgroup_name',
            snapchat: 'name'
        }
    },
    adset_customize_name: {
        properties: {
            meta: 'customize_name',
            tiktok: 'customize_name',
            snapchat: 'customize_name'
        }
    },
    countries: {
        type: 'array',
        properties: {
            meta: 'targeting.geo_locations.locations',
            tiktok: 'location_ids',
            snapchat: 'targeting.geos.country'
        },
        unifiedValues: {
            Netherlands: {
                meta: {
                    key: 'NL',
                    name: 'Netherlands',
                    type: 'country',
                    country_code: 'NL',
                    country_name: 'Netherlands',
                    supports_region: true,
                    supports_city: true
                },
                tiktok: {
                    region_code: 'NL',
                    name: 'Netherlands',
                    support_below_18: true,
                    level: 'COUNTRY',
                    area_type: 'ADMIN',
                    location_id: '2750405',
                    parent_id: '0',
                    snapchat: 'nl'
                },
                snapchat: 'nl'
            },
            Belgium: {
                meta: { key: 'BE', name: 'Belgium', type: 'country', country_code: 'BE', country_name: 'Belgium', supports_region: true, supports_city: true },
                tiktok: {
                    region_code: 'BE',
                    name: 'Belgium',
                    support_below_18: true,
                    level: 'COUNTRY',
                    area_type: 'ADMIN',
                    location_id: '2802361',
                    parent_id: '0'
                },
                snapchat: 'be'
            }
        }
    },
    languages: {
        type: 'array',
        properties: {
            meta: 'targeting.locales',
            tiktok: 'languages'
        },
        unifiedValues: {
            English: {
                meta: {
                    id: 1001,
                    name: 'English (All)'
                },
                tiktok: {
                    code: 'en',
                    name: 'English'
                }
            },
            Dutch: {
                meta: { id: 14, name: 'Dutch' },
                tiktok: {
                    code: 'nl',
                    name: ' Dutch'
                }
            }
        }
    },
    optimization_goal: {
        properties: {
            meta: 'optimization_goal',
            tiktok: 'optimization_goal',
            snapchat: 'optimization_goal'
        },
        unifiedValues: {
            REACH: {
                meta: 'REACH',
                tiktok: 'REACH'
            },
            APP_INSTALLS: {
                meta: 'APP_INSTALLS',
                tiktok: 'INSTALL',
                snapchat: 'APP_INSTALLS'
            },
            IN_APP_EVENT: {
                meta: 'ENGAGED_USERS',
                tiktok: 'IN_APP_EVENT'
            },
            IMPRESSIONS: {
                meta: 'IMPRESSIONS',
                tiktok: 'SHOW',
                snapchat: 'IMPRESSIONS'
            },
            VIDEO_VIEW: {
                meta: 'THRUPLAY',
                tiktok: 'ENGAGED_VIEW_FIFTEEN'
            },
            LEAD_GENERATION: {
                meta: 'LEAD_GENERATION',
                tiktok: 'LEAD_GENERATION'
            },
            PAGE_LIKES: {
                meta: 'PAGE_LIKES',
                tiktok: 'FOLLOWERS'
            },
            PAGE_ENGAGEMENT: {
                meta: 'PAGE_ENGAGEMENT',
                tiktok: 'PROFILE_VEIWS'
            },
            VALUE: {
                meta: 'VALUE',
                tiktok: 'VALUE'
            },
            LANDING_PAGE_VIEWS: {
                meta: 'LANDING_PAGE_VIEWS',
                tiktok: 'TRAFFIC_LANDING_PAGE_VIEW'
            }
        }
    },
    billing_event: {
        properties: {
            meta: 'billing_event',
            tiktok: 'billing_event'
        },
        unifiedValues: {
            CLICKS: {
                meta: 'CLICKS',
                tiktok: 'CPC'
            },
            IMPRESSIONS: {
                meta: 'IMPRESSIONS',
                tiktok: 'CPV'
            }
        }
    },
    bid_type: {
        properties: {
            meta: 'bid_strategy',
            tiktok: 'bid_type',
            snapchat: 'bid_strategy'
        },
        unifiedValues: {
            WITH_CAP: {
                meta: 'LOWEST_COST_WITH_BID_CAP',
                tiktok: 'BID_TYPE_CUSTOM',
                snapchat: 'LOWEST_COST_WITH_MAX_BID'
            },
            WITHOUT_CAP: {
                meta: 'LOWEST_COST_WITHOUT_CAP',
                tiktok: 'BID_TYPE_NO_BID',
                snapchat: 'AUTO_BID'
            }
        }
    },
    bid: {
        properties: {
            meta: 'bid_amount',
            tiktok: 'bid_price',
            snapchat: 'bid_micro'
        }
    },
    pacing: {
        properties: {
            meta: 'pacing_type',
            tiktok: 'pacing',
            snapchat: 'pacing_type'
        },
        unifiedValues: {
            STANDART: {
                meta: ['standard'],
                tiktok: 'PACING_MODE_SMOOTH',
                snapchat: 'STANDARD'
            },
            NO_PACING: {
                meta: ['no_pacing'],
                tiktok: 'PACING_MODE_FAST',
                snapchat: 'ACCELERATED'
            }
        }
    },

    adset_start_time: {
        properties: {
            meta: 'start_time',
            tiktok: 'schedule_start_time',
            snapchat: 'start_time'
        }
    },
    adset_end_time: {
        properties: {
            meta: 'end_time',
            tiktok: 'schedule_end_time',
            snapchat: 'end_time'
        }
    },
    operating_systems: {
        type: 'array',
        properties: {
            meta: 'targeting.user_os',
            tiktok: 'operating_systems'
        },
        unifiedValues: {
            ANDROID: {
                meta: 'ANDROID',
                tiktok: 'Android'
            },
            IOS: {
                meta: 'iOS',
                tiktok: 'Android'
            }
        }
    },
    adset_budget_mode: {
        properties: {
            tiktok: 'budget_mode',
            meta: 'budget_mode',
            snapchat: 'delivery_constraint'
        },
        unifiedValues: {
            LIFETIME: {
                meta: 'LIFETIME',
                tiktok: 'BUDGET_MODE_TOTAL',
                snapchat: 'LIFETIME_BUDGET'
            },
            DAILY: {
                meta: 'DAILY',
                tiktok: 'BUDGET_MODE_DAY',
                snapchat: 'DAILY_BUDGET'
            }
        }
    },
    adset_budget: {
        properties: {
            meta: 'budget',
            tiktok: 'budget',
            snapchat: 'budget'
        }
    },
    gender: {
        properties: {
            meta: 'targeting.genders',
            tiktok: 'gender'
        },
        unifiedValues: {
            MALE: {
                meta: ['1'],
                tiktok: 'GENDER_MALE'
            },
            FEMALE: {
                meta: ['2'],
                tiktok: 'GENDER_FEMALE'
            }
        }
    },
    age: {
        properties: {
            tiktok: 'age_groups',
            meta: 'age'
        },
        unifiedValues: {
            AGE_13_17: {
                tiktok: 'AGE_13_17',
                meta: { min: '13', max: '17' }
            },
            AGE_18_24: {
                tiktok: 'AGE_18_24',
                meta: { min: '18', max: '24' }
            },
            AGE_25_34: {
                tiktok: 'AGE_25_34',
                meta: { min: '25', max: '34' }
            },
            AGE_35_44: {
                tiktok: 'AGE_35_44',
                meta: { min: '35', max: '44' }
            },
            AGE_45_54: {
                tiktok: 'AGE_45_54',
                meta: { min: '45', max: '54' }
            },
            AGE_55_100: {
                tiktok: 'AGE_55_100',
                meta: { min: '55', max: '65' }
            }
        }
    },
    ad_name: {
        properties: {
            meta: 'name',
            tiktok: 'ad_name'
        }
    },
    call_to_action: {
        properties: {
            meta: 'call_to_action_type',
            tiktok: 'call_to_action'
        },
        unifiedValues: {
            APPLY_NOW: {
                meta: 'APPLY_NOW',
                tiktok: 'CALL_NOW'
            },
            BOOK_NOW: {
                meta: 'BOOK_TRAVEL',
                tiktok: 'BOOK_NOW'
            },
            CONTACT_US: {
                meta: 'CONTACT_US',
                tiktok: 'CONTACT_US'
            },
            DOWNLOAD: {
                meta: 'DOWNLOAD',
                tiktok: 'DOWNLOAD_NOW'
            },
            EXPERIENCE_NOW: {
                meta: 'GET_STARTED',
                tiktok: 'EXPERIENCE_NOW'
            },
            GET_QUOTE: {
                meta: 'GET_QUOTE',
                tiktok: 'GET_QUOTE'
            },
            GET_SHOWTIMES: {
                meta: 'GET_SHOWTIMES',
                tiktok: 'GET_SHOWTIMES'
            },
            BUY_TICKETS: {
                meta: 'BUY_TICKETS',
                tiktok: 'GET_TICKETS_NOW'
            },
            INSTALL_APP: {
                meta: 'INSTALL_APP',
                tiktok: 'INSTALL_NOW'
            },
            LEARN_MORE: {
                meta: 'LEARN_MORE',
                tiktok: 'LEARN_MORE'
            },
            LISTEN_NOW: {
                meta: 'LISTEN_NOW',
                tiktok: 'LISTEN_NOW'
            },
            ORDER_NOW: {
                meta: 'ORDER_NOW',
                tiktok: 'ORDER_NOW'
            },
            PLAY_GAME: {
                meta: 'PLAY_GAME',
                tiktok: 'PLAY_GAME'
            },
            READ_MORE: {
                meta: 'SEE_MORE',
                tiktok: 'READ_MORE'
            },
            SHOP_NOW: {
                meta: 'SHOP_NOW',
                tiktok: 'SHOP_NOW'
            },
            SIGN_UP: {
                meta: 'SIGN_UP',
                tiktok: 'SIGN_UP'
            },
            SUBSCRIBE: {
                meta: 'SUBSCRIBE',
                tiktok: 'SUBSCRIBE'
            },
            WATCH_NOW: {
                meta: 'WATCH_VIDEO',
                tiktok: 'WATCH_NOW'
            }
        }
    },
    landing_page_url: {
        properties: {
            meta: 'object_url',
            tiktok: 'landing_page_url'
        }
    }
};
