import { BrickChannel } from '../types/brick.type';

export interface Channel {
    type: BrickChannel;
    title: string;
    description?: string;
    icon: string;
}

const allChannels: Channel[] = [
    { type: 'all', title: 'All', icon: 'apps' },
    { type: 'assets', title: 'Assets', icon: 'photo_library' },
    { type: 'social', title: 'Social', icon: 'share' },
    { type: 'display', title: 'Display', icon: 'space_dashboard' },
    { type: 'video', title: 'Video', icon: 'movie' },
    { type: 'web', title: 'Web', icon: 'space_dashboard' },
    { type: 'email', title: 'Email', icon: 'mail' },
    { type: 'feed', title: 'Feed', icon: 'rss_feed' },
    { type: 'dooh', title: 'DOOH', icon: 'digital_out_of_home' },
    { type: 'group', title: 'Group', icon: 'folder' }
];

export default allChannels;
